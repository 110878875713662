// Use this to write your custom SCSS
.mr-prewrap {
    white-space: pre-wrap;
}

.colour__circle {
    width: 80%;
    padding-bottom: 80%;
    height: 0;
    border-radius: 100%;
    margin: 0 auto;
}

.colour__item {
    float: left;
    width: 225px;
    height: 250px;
    padding: 1rem;
    text-align: center;
}


@media (max-width: 500px) {

    .colour__item {
        width: 90px;
        height: 185px;
        padding: 1rem;
    }

}

.colour__item span {
    display: block;
}

.colour__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Guide-Section{
    border-top: 3px dotted #ccc;
    border-bottom: 3px dotted #ccc;
    padding-top: 10px;
    padding-left: 15px;
}

.modal-overlay {
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    transition: opacity .2s ease;
    z-index: 1000;
}

.modal-overlay.active {
    display: flex;
}

.modal-overlay.visible {
    opacity: 1;
}

.modal-container {
    flex-basis: 50%;
    padding: 1rem;
    background-color: #fff;
    border-radius: 3px;
    position: fixed;
    top: 10%;
}

.modal-header {
    display: flex;
    font-weight: bold;
}

.modal-close {
    margin-left: auto;
    color: inherit;
    text-decoration: none;
    margin-top: -.5rem;
    font-size: 2rem;
}

.modal-content {
    height: 600px;
    overflow: auto;
    padding: 20px;
    width: 840px;
}


.dcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.label {
    position: absolute;
}

.u-link{
    cursor: pointer;
}